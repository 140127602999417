import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ChevronRight from '../images/svgs/chevron.svg';
import SEO from '../components/seo';

const News = ({ data }) => {
  const StyledNewsComponent = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      h1 {
        position: absolute;
        bottom: -5rem;
        left: 50%;
        margin-bottom: 0;
        transform: translate(-50%, -50%);
        color: #ffffff;
        background: ${props => props.theme.colors.org};
        padding: 1.5rem 2.5rem;
        font-size: 2.5rem;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
      @media (max-width: 768px) {
        height: 20vh
      }
    }
  `;
  const StyledNews = styled.div`
    padding: 2rem 0 5rem;
    margin: 8rem 0;
    .news {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .news-block {
        width: 32.5%;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(68, 68, 68, 0.4);
        margin-bottom: 3rem;
        &:first-child {
          width: 65%;
        }
        .photo {
          height: 25rem;
          .gatsby-image-wrapper {
            height: 100%;
          }
        }
        h2 {
          a {
            display: inline-block;
            text-decoration: none;
            color: ${props => props.theme.colors.org};
            padding: 1.5rem 2.5rem 0.3rem;
            font-size: 1.8rem;
            &:hover {
              color: #111111;
            }
          }
        }
        p {
          padding: 0 2.5rem;
          font-family: ${props => props.theme.fonts.sec};
          font-size: 1.6rem;
          color: #111111;
        }
        .learn-more {
          margin-bottom: 2rem;
          display: inline-block;
          padding: 0 2.5rem;
          display: flex;
          align-items: center;
          text-decoration: none;
          font-family: ${props => props.theme.fonts.pri};
          font-size: 1.4rem;
          color: ${props => props.theme.colors.org};
          &:hover {
            color: #111111;
          }
          svg {
            fill: #111111;
          }
        }
      }
    }
    .more-news {
      display: inline-block;
      text-decoration: none;
      cursor: pointer;
      outline: 0;
      margin-top: 3rem;
      border: 2px solid#ffffff;
      padding: 1.8rem 5rem;
      background: transparent;
      font-size: 1.6rem;
      border-radius: 3rem;
      transition: 0.3s all;
      border-color: ${props => props.theme.colors.org};
      color: ${props => props.theme.colors.org};
      &:hover {
        background: ${props => props.theme.colors.org};
        color: #ffffff;
      }
    }
    @media (max-width: 768px) {
      .news {
        flex-direction: column;
        .news-block {
          width: 100%;
          &:first-child {
            width: 100%;
          }
        }
      }
    }
  `;

  const firstPage = data.allWordpressWpNews.nodes.slice(0, 9);

  const [projects, setProjects] = useState(firstPage);
  const [allLoaded, setAllLoaded] = useState(false);

  const onClick = () => {
    let projectsRendered = projects;

    if (projects.length + 6 > data.allWordpressWpNews.nodes.length) {
      projectsRendered = data.allWordpressWpNews.nodes;
      setAllLoaded(true);
    } else {
      projectsRendered = data.allWordpressWpNews.nodes.slice(0, projects.length + 6);
    }

    setProjects(projectsRendered);
  };

  const renderMoreBtn = () => {
    if(!allLoaded && data.allWordpressWpNews.nodes.length > 9) {
      return (
        <button className="more-news" onClick={onClick}>More News</button>
      )
    }
  };

  return (
    <Layout>
      <SEO title="News" />
      <StyledNewsComponent>
        <div className="top-bg">
          <Img fluid={data.file.childImageSharp.fluid} />
          <h1>NEWS</h1>
        </div>
        <StyledNews>
          <div className="container">
            <div className="news">
              {projects.map((news, i) => (
                <div key={i} className="news-block">
                  <div className="photo">
                    <Img fluid={news.acf.news_image.localFile.childImageSharp.fluid} />
                  </div>
                  <h2>
                    <Link to={`/news/${news.slug}`}>{news.acf.news_title}</Link>
                  </h2>
                  <p>
                    {news.acf.excerpt}
                  </p>
                  <Link to={`/news/${news.slug}`} className="learn-more">
                    Learn More <ChevronRight />
                  </Link>
                </div>
              ))}
            </div>
            {renderMoreBtn()}
          </div>
        </StyledNews>
      </StyledNewsComponent>
    </Layout>
  );
};

export const data = graphql`
  {
    file(relativePath: { eq: "n.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpNews {
      nodes {
        slug
        acf {
          news_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2560) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          news_title
          excerpt
        }
      }
    }
  }
`;

export default News;